// Polyfills
import 'es6-promise/auto'

import 'core-js'

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach
}

// https://github.com/fregante/object-fit-images
import objectFitImages from 'object-fit-images'

import ButtonController from 'common/Button/ButtonController'
import InputController from 'common/Input/InputController'
import MainNavController from 'marketing/MainNav/MainNavController'
import ModalSignUp from 'marketing/ModalSignUp/ModalSignup'
import PcaPredictController from 'common/PcaPredict/PcaPredictController'
import RadioController from 'common/Radio/RadioController'
import SelectController from 'common/Select/SelectController'
import TextAreaController from 'common/TextArea/TextAreaController'
import TextRevealController from 'common/TextReveal/TextRevealController'
import TooltipController from 'common/Tooltip/TooltipController'
import VideoController from 'common/Video/VideoController'
import Hamburgers from 'common/Hamburgers/hamburgers'
import LogoSlidingCarouselController from 'marketing/LogoSlidingCarousel/LogoSlidingCarouselController'
import SocialShare from 'marketing/SocialShare/SocialShareController'
import BlogPostCarousel from 'marketing/BlogPostCarousel/BlogPostCarouselController'
import StickyVideo from 'marketing/StickyVideo/StickyVideoController'
import Modal from 'common/Modal/Modal'
import { supportsTouch } from 'utils.js'
import SubNavController from '../components/marketing/SubNav/SubNavController'
import PcaPredict from 'common/PcaPredict/PcaPredict'

$(document).ready(() => {
  objectFitImages()
  $('html').toggleClass('touch', supportsTouch)
  // global class access
  window.zd = {
    ...window.zd,
    Modal,
    ModalSignUp,
    PcaPredict,
    StickyVideo,
  }

  /* eslint-disable no-new */

  new ButtonController()
  new InputController()
  new MainNavController()
  new PcaPredictController()
  new RadioController()
  new SelectController()
  new TextAreaController()
  new TextRevealController()
  new TooltipController()
  new VideoController()
  new Hamburgers()
  new LogoSlidingCarouselController()
  new SocialShare()
  new BlogPostCarousel()
  new SubNavController()
  /* eslint-enable no-new */
})
